import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>School of Future</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>School of Future</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>The ‘Digital Bangladesh Vision 2021’ initiative taken
                by The Government of Bangladesh, led by our honorable Prime Minister Sheikh Hasina.
                The ‘School of Future’ being an extension of ‘Sheikh Russel Digital Lab Project (2nd
                Phase) - 2021’ is a scheme that sheds light in this education field inspired by Smart
                Schools in Finland and ensures the coverage of 300 reputed schools countrywide
                among 300 parliamentary seats, providing compatible IT system, digital content
                creation, learning management system, comprehensive E-learning for students,
                extensive virtual tutoring guidance for teachers, public attention and all necessary
                measures required to create an aim proved teaching and seamless learning
                environment.</p>
                <p>The perspective of this project is to improve the intellect and well-being of students.
                School of Future involves 450,000 Students, 500,000 Parents & 30,000 Teachers
                countrywide.</p>
                <p>The main objective of the School of Future Management System is to expand the
                frontier of learning integrating everyone into a single learning platform. As lead partner,
                Team Creative is been rewarded with project “School of Future” to provide the
                consulting services for “Software Development and Maintenance for 300 School of
                Future (SCHOOL OF FUTURE MANAGEMENT SYSTEM)” under Establishment of
                Sheikh Russel Digital Labs-2nd Phase Project, ICT Division, Department of ICT,
                Government of the People’s Republic of Bangladesh.</p>
                <p>The School of Future is a comprehensive Learning Management System which is
                designed to make the life of students, teachers, administrative staff and parents’ lives
                simpler. This Learning Management System allows all teachers, students and parents to
                be able to study and learn whenever they want, and from anywhere.</p>
                <p>The system provides essential guidance, which means it will feature all
                academic/non-academic modules i.e. Attendance, Academic Calendar, Classwork,
                Homework, etc. Digital content will help all learners in better concept formation, concept
                elaboration, sharpening their critical thinking and upgrading their academic capabilities
                as a whole. The LMS will also provide the ability to encrypt user IDs and passwords
                along with other security measures that ensure data security. There is a dedicated
                dashboard for the administrative team, which will allow them to have a complete
                overview of the entire system. Furthermore, the system is beneficial for the schools as
                well as it maximizes their resources, but more importantly, reduces their costs.</p>
                <p>With the help of The School of Future LMS, students can now learn with the help of
                different forms of media, thus making the learning process more engaging and also,
                bringing a dynamic perspective to education.</p>
                <div className='detailsButton'>
                  <a href='https://sof.edu.bd' target="_blank">More Details</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
