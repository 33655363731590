import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AllRoutes from './Routes';

function App() {
  return (
    <>
      <AllRoutes/>
    </>
  );
}

export default App;
