import React, {Component, Fragment} from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import{Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import AboutMainImg from '../assets/images/image-main-1-01.png';
import AboutBorderImg from '../assets/images/image-layers-border.png';
import AboutTwImg from '../assets/images/image-twiter-1-03.png';
import AboutFbImg from '../assets/images/image-fb-1-04.png';
import AboutInImg from '../assets/images/image-ins.png';


class AboutCompany extends Component{


	render(){
		return(
			<Fragment>
			    <div className="aboutCompany_area">
			    <Container fluid className="about_company_header">
			    	<Row>
			    		<Col xs={12} sm={6} md={6} lg={6} className="about_header_left">
			    			<div className="about_header_left_content">
			    				<h3>Solutions for institutions</h3>
			    				<span>01</span>
								<Link to='#'>
									<div className="about_icon">
										<span></span>
										<FontAwesomeIcon  icon={faLongArrowAltRight} />
									</div>
								</Link>
			    			</div>
			    		</Col>
			    		<Col xs={12} sm={6} md={6} lg={6} className="about_header_right">
			    			<div className="about_header_right_content">
			    				<h3>Our Campaigns and Programs</h3>
			    				<span>02</span>
								<Link to='#'>
									<div className="about_icon">
										<span></span>
										<FontAwesomeIcon  icon={faLongArrowAltRight} />
									</div>
								</Link>
			    			</div>
			    		</Col>
			    	</Row>
			    </Container>
				<Container>
					<Row>
						<Col xs={12} sm={6} md={6} lg={6} className="my-auto">
							<div className="about_company_content">
								<h4>ABOUT Team Creative</h4>
								<h1>Our Mission Is To Digitalize The Education Sector Of Bangladesh</h1>
								<p>Team Creative has been developing E-Business Solutions for the last 12 years with the vision 
									of “Changing People’s Life through Technology”. Our mission is to digitalize the education 
									sector of Bangladesh and equip our nation with smarter solutions. We assist businesses,
									 the defense sector, and government bodies to digitize their business processes for efficient 
									 functionality and help them manage their staff, stakeholders, data, etc.
								</p>
								<div className="company_solution">
									<blockquote>Providing Tech Solutions for Businesses & Consultancy Since 2010</blockquote>
								</div>
							</div>
						</Col>
						<Col xs={12} sm={6} md={6} lg={6}>
							<div className="about_image_content">
								<div className="about_main_img">
									<img src={AboutMainImg} alt="mainImg"/>
								</div>
								<div className="about_border_img">
									<img src={AboutBorderImg} alt="borderImg" />
								</div>
								<div className="all_img_ani_duration about_twiter_img">
									<img src={AboutTwImg} alt="TwiterImg"/>
								</div>
								<div className="all_img_ani_duration about_fb_img">
									<img src={AboutFbImg} alt="FbImg"/>
								</div>
								<div className="all_img_ani_duration about_Ins_img">
									<img src={AboutInImg} alt="InstaImg"/>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
				</div>
			</Fragment>
		)
	}
}

export default AboutCompany;