import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Be Smart, Use Heart</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Be Smart, Use Heart</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>It is with the utmost pride and gratitude that we can say we have been able to carry on this project, “Be Smart, Use Heart” for the last two consecutive years. In 2018, we conducted workshop sessions in 209 schools encompassing 11 districts across 6 divisions in a short time span of only 44 working days. Likewise, in 2019, we covered 379 schools in 20 districts across 8 divisions and established 100 safe clubs in a specific number of schools.</p>
                <p>The last two years have indeed been challenging and despite numerous timing and bureaucratic complexities, we were able to pull in everything together and deliver our best to our targeted audiences which included 209 schools consisting of 400,270 students and 73,947 parents and teachers in 2018 and in 2019, on a much larger scale, we covered 379 schools comprising of 514,640 students and 202,977 teachers and parents. This would not have been possible without a thorough undergoing of our plans and a robust communication system with those on the ground.</p>
                <p>Our initial goal for the year 2020 was to expand the horizon and reach out to more schools in a wider base area hoping to create a more informed and inclusive network of students, parents and teachers. However, due to the pandemic, it was not possible to conduct physical training sessions in all the schools. According to the BRTC, the total number of internet subscribers jumped from 99.8 million to 103.47 million within the space of four months in the first phase of the lockdown period. We thus organized webinars for teachers from all schools where we hosted thorough training sessions. Through this, our ambition for ensuring internet safety throughout the country was not hindered drastically.</p>
                <div className='detailsButton'>
                  <a href='https://champs21.com/safeinternet/' target="_blank">More Details</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
