import React, {Component, Fragment} from 'react';
import {Container,Row,Col,ProgressBar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import visionOne from '../assets/images/vision-1.png';
import visionTwo from '../assets/images/vision-2.png';
import visionThree from '../assets/images/vision-3.png';
import visionFour from '../assets/images/vision-4.png';
import visionFive from '../assets/images/vision-5.png';
import visionSix from '../assets/images/vision-6.png';
import visionSeven from '../assets/images/vision-7.png';

class OurVision extends Component{


    render(){
        return(
            <Fragment>
                <Container className="our_vision" fluid>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className="vision_img">
                                <img src={visionOne} alt="visionImg" />
                                <img className="visionTwo" src={visionTwo} alt="visionImg" />
                                <img className="visionThree" src={visionThree} alt="visionImg" />
                                <img className="visionFour" src={visionFour} alt="visionImg" />
                                <img className="visionFive" src={visionFive} alt="visionImg" />
                                <img className="visionSix" src={visionSix} alt="visionImg" />
                                <img className="visionSeven" src={visionSeven} alt="visionImg" />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className="vision_content">
                                <h4>OUR VISION</h4>
                                <h1>Strategy is at the Heart of What We Do</h1>
                                <p>Our team applies, a wide range of expertise in understanding and determining 
                                    the stategies that will best enable our clients to acheive their strategic 
                                    objectives in the long run and incorporate technology into suited aspects of their objectives</p>

                                 <div className="progressbar">
                                     <h6>DIGITAL STRATEGY</h6>
                                     <label>50%</label>
                                    <ProgressBar now={50}/>
                                    <span></span>
                                 </div>
                                 <div className="progressbar">
                                     <h6>FINANCIAL SERVICES</h6>
                                     <label>70%</label>
                                    <ProgressBar now={70}/>
                                    <span></span>
                                 </div> 
                                 <div className="progressbar">
                                     <h6>COUNSALTING</h6>
                                     <label>100%</label>
                                    <ProgressBar now={100}/>
                                    <span></span>
                                 </div> 
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default OurVision;