import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function SolutionsForInstitutions() {
  
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Business consultancy</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Business consultancy</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>Our team of experts specialize in business administration and Information technology-based solutions, making it your dream team to provide the best-fitted solutions for your business. It is vital to incorporate technology-based approaches into a company's core competencies in today's dynamic environment. We bring you the perfect team to provide consultancy based on the needs of your business and the structure of your organization. Understand what your business needs to grow and sustain by equipping your operations with smart systems that support your strategy in the best way possible.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}

export default SolutionsForInstitutions;
