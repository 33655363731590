import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Bangladesh First</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Bangladesh First</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>In 2013, Bangladesh First began as an educational venture for the country’s youth. The primary goal of this competition was to uphold the importance of having ample knowledge about the motherland for the nation’s students. Rin presents Bangladesh First was aired on Maasranga TV, with 36 episodes.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
