import React, {Component, Fragment} from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker,faEnvelopeOpenText,faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import FooterLogo from '../assets/images/teamcreative.png'

class Footer extends Component{


	render(){
		return(
			<Fragment>
				<Container fluid className="footer_content" >
					<Container>
					 	<Row>
					 		<Col lg={4} md={4} sm={12} xs={12}>
					 			<div className="footer_col_one">
					 				<ul>
					 					<li><FontAwesomeIcon className="mapmarker"  icon={faMapMarker} /> House 54, Road 10, Block E, Enamul Hoq Chowdhury Road, Banani, Dhaka 1213</li>
					 					<li><FontAwesomeIcon  icon={faEnvelopeOpenText} /> info@teamworkbd.com</li>
					 					<li><FontAwesomeIcon  icon={faPhoneAlt} /> 09612212121</li>
					 				</ul>
					 			</div>
					 		</Col>
					 		<Col lg={3} md={3} sm={12} xs={12}>
						 		<div className="footer_col_two">
						 			<ul>
						 				<li><a href="https://classtune.com/">ClassTune</a></li>
						 				<li><a href="https://champs21.com/">Champs21</a></li>
						 				<li><a href="https://spellingbee.champs21.com">Spelling Bee</a></li>
						 				<li><a href="https://champs21.com/safeinternet/">Be Smart, User Heart</a></li>
						 			</ul>
						 		</div>
					 		</Col>
					 		<Col lg={5} md={5} sm={12} xs={12} className="footer_map">
					 			 <iframe
					              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14603.059465911607!2d90.406367!3d23.791386!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xba4c27d85488297d!2sChamps21.com!5e0!3m2!1sen!2sbd!4v1613046646863!5m2!1sen!2sbd"
					              allowFullScreen=""
					              aria-hidden="false"
					              tabIndex="0"
					            />
					 		</Col>
					 	</Row>
					</Container>
				</Container>
				<Container fluid className="footer_bottom_content">
					<Container>
						<Row>
					 		<Col lg={3} md={3} sm={12} xs={12}>
					 			<img src={FooterLogo} alt="footer_logo" />
					 		</Col>
					 		<Col lg={9} md={9} sm={12} xs={12} className="my-auto">
					 			<p className="footer_copy_right">© Copyright 2010-2022 | Team Creative</p>
					 		</Col>
						 </Row>
					</Container>
				</Container>
			</Fragment>
		)
	}
}

export default Footer;