import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>World Teachers Day</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>World Teachers Day</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>CLASSTUNE under Team Creative celebrated World Teachers’ Day nationwide in 2021. Their initiative to pay respect to the teachers of Bangladesh called ‘Saluting the Nation Builders’, continued throughout the month of October with various arrangements.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
