import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Signal Training Center & School</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Signal Training Center & School</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>We created a Learning Management System (LMS) for the Signal Training Center & School in Jessore Cantonment, to use in conducting training for all stakeholders. It's highly scalable, allowing the academy to expand with it. STC&S's authority has 24/7 access to rigorous tracking, completion rates and evaluation findings on subjects, enrollment vs. dropouts and most significantly, KPI.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
