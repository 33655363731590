import React, {Component, Fragment} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons";
import ChooseBg from '../assets/images/section-bg-2.jpg';
import ChooseImgLeft from '../assets/images/section-bg-5.png';
import ChooseRightImg from '../assets/images/section-bg-6.png';
import imagePrinOne from '../assets/images/image-prin-3-01.png';
import imagePrinTow from '../assets/images/image-prin-3-02.png';
import imagePrinThree from '../assets/images/image-prin-3-05.png';
import imagePrinFour from '../assets/images/image-prin-3-04.png';
import imagePrinFive from '../assets/images/image-prin-3-03.png';
import imagePrinSix from '../assets/images/image-prin-3-06.png';

class MainPrinciples extends Component{


	render(){
		return(
			<Fragment>
				<Container className="principal_area" fluid>
					<Row className="text-center">
						<Col xs={12} sm={12} md={12} lg={12}>
							<div className="services_header">
								<h4>OUR STRATEGY</h4>
								<h1>Our Principles</h1>
							</div>
						</Col>
					</Row>
					<div className="principles_content_area">
						<div className ="principles_content_left">
							<div className="princi_content_row">
								<Row>
									<Col xs={3} sm={3} md={3} lg={3} className="my-auto">
										<div className="princi_icon">
											<FontAwesomeIcon  icon={faBusinessTime} />
										</div>
									</Col>
									<Col xs={9} sm={9} md={9} lg={9}>
										<div className="princi_content">
											<h4>Sustainablity</h4>
											<p>We belive in creating sustainable solutions with a vision to contribute to the future of our nation.</p>
										</div>
									</Col>
								</Row>
							</div>
							<div className="princi_content_row princi_left_third">
								<Row>
									<Col xs={3} sm={3} md={3} lg={3} className="my-auto">
										<div className="princi_icon">
											<FontAwesomeIcon  icon={faBusinessTime} />
										</div>
									</Col>
									<Col xs={9} sm={9} md={9} lg={9}>
										<div className="princi_content">
											<h4>Work Ethics</h4>
											<p>We believe in putting the needs of the society and our clients before everything.</p>
										</div>
									</Col>
								</Row>
							</div>
							<div className="princi_content_row">
								<Row>
									<Col xs={3} sm={3} md={3} lg={3} className="my-auto">
										<div className="princi_icon">
											<FontAwesomeIcon  icon={faBusinessTime} />
										</div>
									</Col>
									<Col xs={9} sm={9} md={9} lg={9}>
										<div className="princi_content">
											<h4>Close-knit Work Groups</h4>
											<p>We strongly belive in building Close-knit work groups to cater better to the needs of our customer.</p>
										</div>
									</Col>
								</Row>
							</div>
						</div>
						<div className="principles_content_middle">
							<div className="princi_img_content">
								<img src={imagePrinOne} alt="prinImg" />
							</div>
							<div className="princi_img_ani_2">
								<img src={imagePrinTow} alt="prinImg" />
							</div> 
							<div className="princi_img_ani_3">
								<img src={imagePrinThree} alt="prinImg" />
							</div> 
							<div className="princi_img_ani_4">
								<img src={imagePrinFour} alt="prinImg" />
							</div> 
							<div className="princi_img_ani_5">
								<img src={imagePrinFive} alt="prinImg" />
							</div>
							<div className="princi_img_ani_6">
								<img src={imagePrinSix} alt="prinImg" />
							</div> 
						</div>
						<div className="principles_content_right">
							<div className="princi_content_row">
								<Row>
									<Col xs={3} sm={3} md={3} lg={3} className="my-auto">
										<div className="princi_icon">
											<FontAwesomeIcon  icon={faBusinessTime} />
										</div>
									</Col>
									<Col xs={9} sm={9} md={9} lg={9}>
										<div className="princi_content">
											<h4>Technology For A Better Tomorrow</h4>
											<p>Our vision is  "Changing People’s Life through Technology"</p>
										</div>
									</Col>
								</Row>
							</div>
							<div className="princi_content_row princi_right_third">
								<Row>
									<Col xs={3} sm={3} md={3} lg={3} className="my-auto">
										<div className="princi_icon">
											<FontAwesomeIcon  icon={faBusinessTime} />
										</div>
									</Col>
									<Col xs={9} sm={9} md={9} lg={9}>
										<div className="princi_content">
											<h4>Creative Solutions</h4>
											<p>We provide creative solutions to acheive your business objectives</p>
										</div>
									</Col>
								</Row>
							</div>
							<div className="princi_content_row">
								<Row>
									<Col xs={3} sm={3} md={3} lg={3} className="my-auto">
										<div className="princi_icon">
											<FontAwesomeIcon  icon={faBusinessTime} />
										</div>
									</Col>
									<Col xs={9} sm={9} md={9} lg={9}>
										<div className="princi_content">
											<h4>Organization</h4>
											<p>Strategy experience and analytical expertise combine to enable.</p>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</Container>
			</Fragment>
		)
	}
}

export default MainPrinciples;