import React, {Component, Fragment} from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlLogoOne from '../assets/images/partners/partners-1c.png';
import OwlLogoTwo from '../assets/images/partners/partners-2c.png';
import OwlLogoThree from '../assets/images/partners/partners-3c.png';
import OwlLogoFour from '../assets/images/partners/partners-4c.png';
import OwlLogoFive from '../assets/images/partners/partners-5c.png';
import OwlLogoSix from '../assets/images/partners/partners-6c.png';
import OwlLogoSeven from '../assets/images/partners/partners-7c.png';
import OwlLogoEight from '../assets/images/partners/partners-8c.png';
import OwlLogoNine from '../assets/images/partners/partners-9c.png';
import OwlLogoTen from '../assets/images/partners/partners-10c.png';
import OwlLogoEleven from '../assets/images/partners/partners-11c.png';
import OwlLogoTwelve from '../assets/images/partners/partners-12c.png';
import OwlLogo13 from '../assets/images/partners/partners-13c.png';
import OwlLogo14 from '../assets/images/partners/partners-14c.png';
import OwlLogo15 from '../assets/images/partners/partners-15c.png';
import OwlLogo16 from '../assets/images/partners/partners-16c.png';
import OwlLogo17 from '../assets/images/partners/partners-17c.png';
import OwlLogo18 from '../assets/images/partners/partners-18c.png';
import OwlLogo19 from '../assets/images/partners/partners-19c.png';
import OwlLogo20 from '../assets/images/partners/partners-20c.png';
import OwlLogo21 from '../assets/images/partners/partners-21c.png';
import OwlLogo22 from '../assets/images/partners/partners-22c.png';
import OwlLogo23 from '../assets/images/partners/partners-23c.png';
import OwlLogo24 from '../assets/images/partners/partners-24c.png';
import OwlLogo25 from '../assets/images/partners/partners-25c.png';
import OwlLogo26 from '../assets/images/partners/partners-26c.png';
import OwlLogo27 from '../assets/images/partners/partners-27c.png';
import OwlLogo28 from '../assets/images/partners/partners-28c.png';
import OwlLogo29 from '../assets/images/partners/partners-29c.png';
import OwlLogo30 from '../assets/images/partners/partners-30c.png';
import OwlLogo31 from '../assets/images/partners/partners-31c.png';
import OwlLogo32 from '../assets/images/partners/partners-32c.png';
import OwlLogo33 from '../assets/images/partners/partners-33c.png';
import OwlLogo34 from '../assets/images/partners/partners-34c.png';
import OwlLogo35 from '../assets/images/partners/partners-35c.png';
import OwlLogo36 from '../assets/images/partners/partners-36c.png';
import OwlLogo37 from '../assets/images/partners/partners-37c.png';
import OwlLogo38 from '../assets/images/partners/partners-38c.png';
import OwlLogo39 from '../assets/images/partners/partners-39c.png';



class Partners extends Component{
	state= {
        responsive:{
            0: {
                items: 1,
            },
            360: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 6,
            },
        },
    }

	render(){
		return(
			<Fragment>
			    <div className="partners_area">
			    	<Row className="text-center">
						<Col xs={12} sm={12} md={12} lg={12}>
							<div className="services_header">
								<h4>Partners</h4>
								<h1>Some of our valuable partners</h1>
							</div>
						</Col>
					</Row>
			    	<OwlCarousel className='owl-theme' loop margin={10} items={6} margin={10} autoplay={true} responsive={this.state.responsive}>
			    	    <div className='item'>
			    	        <img src={OwlLogoOne} alt="OwlLogo" />
			    	    </div>
			    	    <div className='item'>
			    	        <img src={OwlLogoTwo} alt="OwlLogo" />
			    	    </div>
			    	    <div className='item'>
			    	        <img src={OwlLogoThree} alt="OwlLogo" />
			    	    </div>
			    	    <div className='item'>
			    	        <img src={OwlLogoFour} alt="OwlLogo" />
			    	    </div>
			    	    <div className='item'>
			    	        <img src={OwlLogoFive} alt="OwlLogo" />
			    	    </div>
			    	    <div className='item'>
			    	        <img src={OwlLogoSix} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogoSeven} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogoEight} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogoNine} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogoTen} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogoEleven} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogoTwelve} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo13} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo14} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo15} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo16} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo17} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo18} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo19} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo20} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo21} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo22} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo23} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo24} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo25} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo26} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo27} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo28} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo29} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo30} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo31} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo32} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo33} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo34} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo35} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo36} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo37} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo38} alt="OwlLogo" />
			    	    </div>
						<div className='item'>
			    	        <img src={OwlLogo39} alt="OwlLogo" />
			    	    </div>
			    	</OwlCarousel>
				</div>
			</Fragment>
		)
	}
}

export default Partners;