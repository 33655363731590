import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from './HeaderNav';
import Footer from './Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Bangladesh First</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Campaigns</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p><strong>Skill development for mobile app training:</strong> In addition to the extensive industrial experience in human resources management,
                we have a pool of experts in our capacity specializing in specific areas. We design and deliver high-impact Training & Skill development programs
                and a wide range of services. Organized by the ICT division to develop a skilled pool of professionals who can contribute to the local and
                international App and gaming industry. We deployed training for Mobile App Developer on both iOS & Android platforms. We were able to reach
                out to 4,375 trainees from the entire country.</p>
                <p><strong>National Defense College:</strong> The National Defense College is a pioneering institute in the field of defense, which has been using our
                LMS, NDC 4.0 The simple-to-use system works across all platforms with real-time updates. NDC 4.0 elements were planned and implemented to encompass all
                important academic material so that all stakeholders may access it in a systematic manner based on their needs. This LMS houses all of the organization's
                academic data. The technology enhances the collaboration between students and teachers, as well as creates a scope for the administration to keep\
                tabs of their work.</p>
                <p><strong>Signal Training Center & School:</strong> We created a Learning Management System (LMS) for the Signal Training Center & School in Jessore
                Cantonment, to use in conducting training for all stakeholders. It's highly scalable, allowing the academy to expand with it. STC&S's authority
                has 24/7 access to rigorous tracking, completion rates and evaluation findings on subjects, enrollment vs. dropouts and most significantly,
                KPI.</p>
                <p><strong>Spelling Bee:</strong>Spelling Bee: Bangladesh's first online education platform Champs21.com was Team Creative's debut project.
                It's an online learning system that allows students
                to brush up on their beyond-textbook knowledge in both Bangla and English.</p>
                <p>In 2012 Champs21.com under Team Creative launched “Spelling Bee” which is a competition where contestants need to spell a wide range of words.
                It is for students in grades 6 through 10 from both Bangla and English-medium schools who compete via their schools and/or the Champs21.com.
                website.</p>
                <p>The Spelling Bee, one of the most popular Education TV reality shows, became a nationwide sensation every season.</p>
                <p><strong>World Teachers Day:</strong> CLASSTUNE under Team Creative celebrated World Teachers’ Day nationwide in 2021.
                Their initiative to pay respect to the teachers of Bangladesh called ‘Saluting the Nation Builders’, continued throughout
                the month of October with various arrangements.</p>
                <p><strong>Bangladesh First:</strong>In 2013, Bangladesh First began as an educational venture for the country’s youth.
                The primary goal of this competition was to uphold the importance of having ample knowledge about the motherland for the nation’s students.
                Rin presents Bangladesh First was aired on Maasranga TV, with 36 episodes.</p>
                <p><strong>Bangladesh First:</strong>In 2013, Bangladesh First began as an educational venture for the country’s youth. The primary
                goal of this competition was to uphold the importance of having ample knowledge about the motherland for the nation’s students. Rin 
                presents Bangladesh First was aired on Maasranga TV, with 36 episodes.</p>
                <p><strong>Science Rocks:</strong>To eradicate the fear of science and make it an interesting topic for children, our team came up
                with the show Doze presents Science Rocks. The show was aired on Channel i, which is one of the leading channels in all of Bangladesh.
                A web portal and mobile application were developed and then moderated by experienced science teachers. Students were able to ask questions,
                take quizzes and also get help from each other conveniently.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
