import React, {Component, Fragment} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ChooseBg from '../assets/images/section-bg-2.jpg';
import ChooseImgLeft from '../assets/images/section-bg-5.png';
import ChooseRightImg from '../assets/images/section-bg-6.png';

class WhyChoose extends Component{


	render(){
		return(
			<Fragment>
				<Container className="choose_area" fluid>
					<Row>
						<Col xs={12} sm={12} md={6} lg={6} className="pl-0 pr-0">
							<div className="choose_left_content" style={{background: `url(${ChooseBg})`,}}>
								<img src={ChooseImgLeft} alt="choose_left" />
							</div>
						</Col>
						<Col xs={12} sm={12} md={6} lg={6} className="pl-0">
							<div className="choose_right_content">
								<h4>OUR BENEFITS</h4>
								<h1>Why Choose US?</h1>
								<p>We seamelessly merge two key components - your business processes or business strategy with Information technology.</p>
								<div className="choose_sub_content">
									<Row>
										<Col xs={1} sm={1} md={1} lg={1}>
											<div className="choose_correct">
												<FontAwesomeIcon  icon={faCheck} />
											</div>
										</Col>
										<Col xs={11} sm={11} md={11} lg={11} className="pl-0">
											<div className="choose_step">
											<p>A Wide Variety of Services
													<ul>
														<li>Customise your own LMS or TMS</li>
														<li>We provide support staff to implement our systems in your institution.</li>
														<li>Training sessions for implementing new technology in your institution</li>
														<li>Maintainance and data backup</li>
														<li>Design Mobile apps and websites</li>
													</ul>
												</p>
											</div>
										</Col>
									</Row>
									<Row>
										<Col xs={1} sm={1} md={1} lg={1}>
											<div className="choose_correct">
												<FontAwesomeIcon  icon={faCheck} />
											</div>
										</Col>
										<Col xs={11} sm={11} md={11} lg={11} className="pl-0">
											<div className="choose_step">
												<p>We believe in
													<ul>
														<li>High standards of work ethics</li>
														<li>Proffessionalism</li>
														<li>Understanding our customers and their businesses.</li>
														<li>Close-knit working teams to cater to your needs</li>
														<li>Meeting deadlines</li>
													</ul>
												</p>
											</div>
										</Col>
									</Row>
									<Row>
										<Col xs={1} sm={1} md={1} lg={1}>
											<div className="choose_correct">
												<FontAwesomeIcon  icon={faCheck} />
											</div>
										</Col>
										<Col xs={11} sm={11} md={11} lg={11} className="pl-0">
											<div className="choose_step">
												<p>We contribute to
													<ul>
														<li>Fulfilling the vision of a Digital Bangladesh</li>
														<li>The education sector of Bangladesh</li>
														<li>The government and the defence sector of Bangladesh</li>
													</ul>
												</p>
											</div>
										</Col>
									</Row>
									<img src={ChooseRightImg} alt="chooseRImg"/>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</Fragment>
		)
	}
}

export default WhyChoose;