import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Class Admission</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Class Admission</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>The Online Admission System is a much easier way of admitting students for the new session of a school. By using the Online Admission System, one can simply fill up the admission application form anytime from wherever they want. This takes away the hassle of waiting in long queues. They can pay the admission fees online through mobile banking platforms such as bKash, TCash, Rocket, etc, and also pay using their credit/debit cards online.</p>
                <p>The Class Admission platform is a comprehensive resource for college and school applicants. It enables you to create and manage an account via which you can collect admission forms and admit cards, you can make payments and also keep track of the progress of your application.
                </p>
                <div className='detailsButton'>
                  <a href='https://classtune.com/admission/' target="_blank">More Details</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
