import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>ClassPay</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>ClassPay</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>CLASSPAY is the leading online fees collection platform in Bangladesh. With an aim to make the fees collection process easy, fast and secure for all educational institutions across Bangladesh, CLASSPAY serves as a financial application for CLASSTUNE; the country’s best Learning Management System.
                </p>
                <p>CLASSPAY allows the users to finish the whole fees payment process, and access related information digitally, instantly, and accurately in real-time. This reduces time, effort, and human resources, making the process fast, safe, and non-erroneous.
                </p>
                <div className='detailsButton'>
                  <a href='https://classpay.com.bd' target="_blank">More Details</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
