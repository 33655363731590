import React from 'react';
import HeaderNav from './HeaderNav';
import Slider from './Slider';
import AboutCompany from './AboutCompany';
import Services from './Services';
import WhyChoose from './Whychoose';
import MainPrinciples from './MainPrinciples';
import CountsUp from './CountsUp';
import OurVision from './OurVision';
import Partners from './Partners';
import Comments from './Comments';
import Footer from './Footer';

function HomePage() {
  return (
    <div>
      <HeaderNav/>
      <Slider/>
      <AboutCompany/>
      <Services/>
      <WhyChoose/>
      <MainPrinciples/>
      <CountsUp/>
      <OurVision/>
      <Partners/>
      <Comments/>
      <Footer/>
    </div>
  );
}

export default HomePage;
