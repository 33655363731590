import React, {Component, Fragment} from 'react';
import { Link, useLocation } from 'react-router-dom';
import {Navbar,Nav,Form,Button } from 'react-bootstrap';
import whiteLogo from '../assets/images/teamcreative.png'
import blackLogo from '../assets/images/teamcreative-black.png'
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';

class HeaderNav extends Component {

  constructor(){
      super();
      this.state={
          navBarLogo:[whiteLogo],
          navBarBack:"navBackground",
          navBarBackgroundColor:"",
      }
  }

  onScroll=()=>{
        if(window.scrollY>100){
            this.setState({navBarBack:'navBackgroundScroll',navBarLogo:[blackLogo]});
            this.setState({navBarBackgroundColor: ""});
        }
        else if(window.scrollY<100){
            this.setState({navBarBack:'navBackground',navBarLogo:[whiteLogo]});
            this.setState({navBarBackgroundColor: this.props.bgColor});
        }
    }
    componentDidMount() {
        window.addEventListener('scroll',this.onScroll);
        this.setState({navBarBackgroundColor: this.props.bgColor});
    }

    render() {
      return (
        <Fragment>
              <Navbar style={{backgroundColor: this.state.navBarBackgroundColor}} expand="lg" className="pl-5 pr-5" className={this.state.navBarBack} fixed="top">
                <Navbar.Brand href="#home"><Link to="/"><img src={this.state.navBarLogo} alt="Logo"/></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="m-auto">
                    <Link className="nav-link" to="/">Home</Link>
                    <Link className="nav-link sub_menu" to="#">About Us
                      <ul>
                        <li><Link className="nav-links" to="/company-overview">Company Overview</Link></li>
                        <li><Link className="nav-links" to="/mission-vision">Mission & Vision</Link></li>
                      </ul>
                    </Link>
                    <Link className="nav-link sub_menu" to="/">Products
                      <ul>
                        <li><Link className="nav-links" to="/classtune">ClassTune</Link></li>
                        <li><Link className="nav-links" to="/classpay">ClassPay</Link></li>
                        <li><Link className="nav-links" to="/edoozz">EDOOZZ</Link></li>
                        <li><Link className="nav-links" to="/class-admission">Class Admission</Link></li>
                        <li><Link className="nav-links" to="/class-payroll">Class Payroll</Link></li>
                      </ul>
                    </Link>
                    <Link className="nav-link" to="/portfolio ">Portfolio</Link>
                    <Link className="nav-link sub_menu" to="/#">Solution for Institutions
                      <ul>
                        <li><Link className="nav-links" to="/business-consultancy">Business Consultancy</Link></li>
                        <li><Link className="nav-links" to="/digital-solution">Digital Solution</Link></li>
                        <li><Link className="nav-links" to="/website">Website</Link></li>
                        <li><Link className="nav-links" to="/app-development">App Development</Link></li>
                        <li><Link className="nav-links" to="/cloud-computing">Cloud Computing</Link></li>
                      </ul>
                    </Link>
                    <Link className="nav-link sub_menu" to="/# ">Projects & Campaigns
                      <ul>
                        <li><Link className="nav-links" to="/sof">School of Future</Link></li>
                        <li><Link className="nav-links" to="/spelling-bee">Spelling Bee</Link></li>
                        <li><Link className="nav-links" to="/be-smart-use-heart">Be Smart, Use Heart</Link></li>
                        <li><Link className="nav-links" to="/science-rocks">Science Rocks</Link></li>
                        <li><Link className="nav-links" to="/bangladesh-first">Bangladesh First</Link></li>
                        <li><Link className="nav-links" to="/ndc">NDC</Link></li>
                        <li><Link className="nav-links" to="/stcs">STCS</Link></li>
                        <li><Link className="nav-links" to="/world-teachers-day">World Teachers Day</Link></li>
                        <li><Link className="nav-links" to="/undp-ttt">UNDP TTT</Link></li>
                      </ul>
                    </Link>
                  </Nav>
                  <Form inline>
                    <Nav.Link href="#link">
                      <Button variant="outline-success">Contact</Button>
                    </Nav.Link>
                  </Form>
                </Navbar.Collapse>
              </Navbar>
        </Fragment>
      );
    }
}

export default HeaderNav;

