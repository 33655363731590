import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';
import PageBgImg from '../assets/images/pagebg.png'

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Mission & Vision</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Mission & Vision</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>The usage of learning methods and tools are advancing with time and getting more and more important with the demand for continuous self-assessments.
                    We strive to make life easier, provide an easy to use, transparent value adding system for automation, which will ensure seamless experience for end users.
                </p>
                <p>Our team doesn’t only consist of young professionals but also people who have years of experience. This mixture doesn’t only assure innovation, but perfection as well.
                    Efficiency, proactive leadership, innovation and creativity are the main strengths of Team Creative.
                </p>
                <p>Team Creative may be new in the global arena, but has been working in the sector of education and technology for 10 years along with a team who is inspired
                 to be not just their best but the best in the industry. The teams of experts that work with us, bring years of experience and creativity to the organization. 
                 We also have a group of young contributors who bring in new ideas and energy to our table. We are a group of creative people who work to develop innovative
                ideas for their clients.
                </p>
                <p><strong>Mission:</strong> Our goal is to utilize our collective resources to provide holistic and effective digital solutions to EMPOWER EDUCATION.</p>
                <p><strong>Vision:</strong>We are dedicated to reach corners of not only Bangladesh, but also the world to encourage, engage and create opportunities for youngsters.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
