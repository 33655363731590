import React, {Component, Fragment} from 'react';
import {Container,Row,Col,Card} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import CountUpBg from '../assets/images/section-bg-8.png';
import CountUpRight from '../assets/images/yellow-ani.png';

class CountsUp extends Component{


    render(){
        return(
            <Fragment>
                <Container className="text-center countSection_area">
                    <img className="count_up_bg" src={CountUpBg} alt="countbg" />
                    <img className="count_up_right" src={CountUpRight} alt="countbg" />
                    <Row className="countSection">
                        <Col lg={3} md={3} sm={6} xs={12} className="count_col">
                            <h1 className="countNumber">
                                <CountUp start={0} end={250}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                 </CountUp>
                             </h1>
                             <FontAwesomeIcon  icon={faPlus} />
                             <h4 className="countTitle">YEARS OF <br/> EXPERIENCE</h4>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className="count_col">
                            <h1 className="countNumber">
                                <CountUp start={0} end={350}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </h1>
                            <FontAwesomeIcon  icon={faPlus} />
                            <h4 className="countTitle">TRUSTED <br/> CLIENTS</h4>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className="count_col">
                            <h1 className="countNumber">
                                <CountUp start={0} end={1500000}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </h1>
                            <FontAwesomeIcon  icon={faPlus} />
                            <h4 className="countTitle">ACTIVE <br/> USERS</h4>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className="count_col">
                            <h1 className="countNumber">
                                <CountUp start={0} end={12}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </h1>
                            <FontAwesomeIcon  icon={faPlus} />
                            <h4 className="countTitle">YEARS OF <br/> SERVICE</h4>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default CountsUp;