import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Company Overview</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Company Overview</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p><strong>About US:</strong> Team Creative is a Bangladesh based EdTech firm that uses technology to make teaching and learning significantly simpler.
                    We offer a wide range of services by leveraging our domain, business expertise and innovations of the brightest experts. 
                    We have been working in Bangladesh's education sector collaborating with a variety of institutions, corporations, the
                    defense and the government to digitize the country's education system. 
                </p>
                <p>Our area of expertise is integrating technology into education. Ultimately we want to make education based technology that is easy to observe and
                    user-friendly for all stakeholders. Team Creative has domain experience in a variety of sectors, as well as technical skills in platforms like iOS
                    and Android. Team Creative also works with web engines and apps to develop instructional material, focusing on graphics design, 2D and 3D animation.
                    Team Creative also offers a comprehensive set of services and outsourced solutions to help expanding enterprises and educational institutions gain
                    a competitive edge in national and international marketplaces.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
