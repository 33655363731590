import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Spelling Bee</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Spelling Bee</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>Bangladesh's first online education platform Champs21.com was Team Creative's debut project. It's an online learning system that allows students to brush up on their beyond-textbook knowledge in both Bangla and English.</p>
                <p>In 2012 Champs21.com under Team Creative launched “Spelling Bee” which is a competition where contestants need to spell a wide range of words. It is for students in grades 6 through 10 from both Bangla and English-medium schools who compete via their schools and/or the Champs21.com. website.</p>
                <p>The Spelling Bee, one of the most popular Education TV reality shows, became a nationwide sensation every season.</p>
                <div className='detailsButton'>
                  <a href='https://spellingbee.champs21.com/' target="_blank">More Details</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
