import React, {Component, Fragment} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileWord, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import ServicesImgOne from '../assets/images/services/services-1.jpg';
import ServicesImgTwo from '../assets/images/services/services-2.jpg';
import ServicesImgThree from '../assets/images/services/services-3.jpg';
import ServicesImgFour from '../assets/images/services/services-4.jpg';
import ServicesImgFive from '../assets/images/services/services-5.jpg';
import ServicesImgSix from '../assets/images/services/services-6.jpg';
import Logo1 from '../assets/images/services/logo-1.png';
import Logo2 from '../assets/images/services/logo-2.png';
import Logo3 from '../assets/images/services/logo-3.png';
import Logo4 from '../assets/images/services/logo-4.png';
import Logo5 from '../assets/images/services/logo-5.png';
import Logo6 from '../assets/images/services/logo-6.jpg';

class Services extends Component{


	render(){
		return(
			<Fragment>
				<Container className="services_area">
					<Row className="text-center">
						<Col xs={12} sm={12} md={12} lg={12}>
							<div className="services_header">
								<h4>WHAT WE OFFER</h4>
								<h1>Our Programs & Projects</h1>
								<p>We are specialists in both Eduction and information technologies and we apply our full
								 range of talent to creating the perfect solution for each client’s needs.</p>
							</div>
						</Col>
					</Row>

					<Row className="service_content_row">
						<Col xs={12} sm={6} md={4} lg={4}>
							<a href='https://champs21.com/' target='_blank'>
								<div className="services_content">
									<img src={ServicesImgOne} alt="serImg" />
									<div className="service_overlay"></div>
									<div className="service_icon">
										<img src={Logo6} />
										{/* <FontAwesomeIcon  icon={faFileWord} /> */}
									</div>
									<h1>Champs21.com</h1>
									<div className="service_parag">
										<p>Strategy experience and analytical expertise combine to enable decision making and create value.</p>
									</div>
									<div className="serice_arrow_icon">
										<FontAwesomeIcon  icon={faLongArrowAltRight} />
									</div>
								</div>
							</a>
						</Col>
						<Col xs={12} sm={6} md={4} lg={4}>
							<a href='https://classtune.com' target='_blank'>
								<div className="services_content">
									<img src={ServicesImgTwo} alt="serImg" />
									<div className="service_overlay"></div>
									<div className="service_icon">
										<img style={{width:"129px",marginTop:"calc(100% - 107px)",marginLeft:"calc(100% - 105px)"}} src={Logo2} />
										{/* <FontAwesomeIcon  icon={faFileWord} /> */}
									</div>
									<h1>ClassTune</h1>
									<p>Strategy experience and analytical expertise combine to enable decision making and create value.</p>
									<div className="serice_arrow_icon">
										<FontAwesomeIcon  icon={faLongArrowAltRight} />
									</div>
								</div>
							</a>
						</Col>
						<Col xs={12} sm={6} md={4} lg={4}>
							<a href='https://www.edoozz.com' target='_blank'>
								<div className="services_content">
									<img src={ServicesImgThree} alt="serImg" />
									<div className="service_overlay"></div>
									<div className="service_icon">
										<img style={{marginTop:"calc(100% - 57px)"}} src={Logo3} />
										{/* <FontAwesomeIcon  icon={faFileWord} /> */}
									</div>
									<h1>Edoozz</h1>
									<p>Strategy experience and analytical expertise combine to enable decision making and create value.</p>
									<div className="serice_arrow_icon">
										<FontAwesomeIcon  icon={faLongArrowAltRight} />
									</div>
								</div>
							</a>
						</Col>
						<Col xs={12} sm={6} md={4} lg={4}>
							<a href='https://sof.edu.bd' target='_blank'>
								<div className="services_content">
									<img src={ServicesImgFour} alt="serImg" />
									<div className="service_overlay"></div>
									<div className="service_icon">
										<img style={{marginTop:"calc(100% - 65px)"}} src={Logo5} />
										{/* <FontAwesomeIcon  icon={faFileWord} /> */}
									</div>
									<h1>School of Future</h1>
									<p>Strategy experience and analytical expertise combine to enable decision making and create value.</p>
									<div className="serice_arrow_icon">
										<FontAwesomeIcon  icon={faLongArrowAltRight} />
									</div>
								</div>
							</a>
						</Col>
						<Col xs={12} sm={6} md={4} lg={4}>
							<div className="services_content">
								<img src={ServicesImgFive} alt="serImg" />
								<div className="service_overlay"></div>
								<div className="service_icon">
									<img style={{marginTop:"calc(100% - 65px)"}} src={Logo1} />
									{/* <FontAwesomeIcon  icon={faFileWord} /> */}
								</div>
								<h1>Apps Development</h1>
								<p>Strategy experience and analytical expertise combine to enable decision making and create value.</p>
								<div className="serice_arrow_icon">
									<FontAwesomeIcon  icon={faLongArrowAltRight} />
								</div>
							</div>
						</Col>
						<Col xs={12} sm={6} md={4} lg={4}>
							<a href='https://spellingbee.champs21.com' target='_blank'>
								<div className="services_content">
									<img src={ServicesImgSix} alt="serImg" />
									<div className="service_overlay"></div>
									<div className="service_icon">
										<img style={{marginTop:"calc(100% - 75px)"}} src={Logo4} />
										{/* <FontAwesomeIcon  icon={faFileWord} /> */}
									</div>
									<h1>Spelling Bee</h1>
									<p>Strategy experience and analytical expertise combine to enable decision making and create value.</p>
									<div className="serice_arrow_icon">
										<FontAwesomeIcon  icon={faLongArrowAltRight} />
									</div>
								</div>
							</a>
						</Col>
					</Row>
				</Container>
			</Fragment>
		)
	}
}

export default Services;