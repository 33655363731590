import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function SolutionsForInstitutions() {
  
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>App Development</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>App Development</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>Our team of experienced IT specialists know just the right recipe to create and develop an app to your taste and requirement. Consult with our team, claim your requirements, customise to suit your needs, and have the necessities delivered to your doorstep. Equip your app with a user-friendly interface, smart tabs, interactive methods, scanning information and smart tools to enhance your product and business. </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}

export default SolutionsForInstitutions;
