import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>National Defense College</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>National Defense College</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>The National Defense College is a pioneering institute in the field of defense, which has been using our LMS, NDC 4.0 The simple-to-use system works across all platforms with real-time updates. NDC 4.0 elements were planned and implemented to encompass all important academic material so that all stakeholders may access it in a systematic manner based on their needs. This LMS houses all of the organization's academic data. The technology enhances the collaboration between students and teachers, as well as creates a scope for the administration to keep tabs of their work.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
