import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>ClassTune</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>ClassTune</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>ClassTune is a Comprehensive Learning Management System, aimed to build a common interactive platform for all stakeholders including all
                students, parents, teachers, and the administrative teams of all schools to collaborate and embrace technology, evolving from the old school method. It is a cloud based
                Software as a Service (SaaS) where all involved parties have access to the system, via Internet from desktop or smartphone. It is intended to empower students,
                teaching staff and other educational support staff to better collaborate, exchange information, provide & receive education 
                </p>
                <p>CLASSTUNE helps to amalgamate all important data of students and teachers in a deliberate manner to help the school administrative head or the appointment in charge
                  to monitor all curricular activities with a business intelligence dashboard where parameters for different measures can be set, reports collected, enabling easier 
                  evaluation and effective decision making. Each parent gets access to data or notifications directly from the school and can easily check their child’s educational updates. Instructors can also check their assigned work, monitor the progress of students and provide effective feedback. CLASSTUNE can be used as an online school. Educators' can take the assessments, assign homework and upload their class lectures.
                </p>
                <div className='detailsButton'>
                  <a href='https://classtune.com/' target="_blank">More Details</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
