import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>EDOOZZ</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>EDOOZZ</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>EDOOZZ is a Learning Management System (LMS which is basically a cloud based Software as a Service (SaaS)). All involved parties have access to the system, via the internet from desktop or smartphone. Real time updates on academic, extra- curricular and administrative issues are available. Moreover, updates are tailored to specific individuals such as course members, management, faculty or Grouped (for hierarchy level, subject level for course faculty & principal).</p>
                <p>We provide university faculties and businesses with the tool to bridge the gap between academic excellence and convenience. Education is a tool that we can use to navigate our way through this world and the people who play the biggest role in accomplishing it; our teachers. They provide us with a safe space to learn and grow. Teachers facilitate our journey towards excellence by providing us with knowledge and direction. We at EDOOZZ believe that it is time we step up to help students in their pursuit of knowledge by providing teachers a platform to extend the horizon of knowledge to their students. Thus, we have made the process of obtaining knowledge much easier and faster. With the help of our platform, teachers can now reach out beyond the wall of their classrooms and assist students in a more organized yet convenient manner. Achieving higher academic excellence is no longer a privilege but rather a convenience.
                </p>
                <div className='detailsButton'>
                  <a href='https://www.edoozz.com' target="_blank">More Details</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
