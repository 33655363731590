import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function SolutionsForInstitutions() {
  
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Digital Solutions</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Digital Solutions</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>Businesses looking for productive solutions for their day-to-day work benefit the most when they integrate digital processes into their operations and decision-making. Choose from a plethora of services, options and packages. Land the best project for your organization while seeking creative solutions from our shelves that best suit your plans. Starting from software development, web and app design and development to training tech-support teams, we have the full package for your business.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}

export default SolutionsForInstitutions;
