import React, {Component, Fragment} from 'react';
import {Carousel,Button} from 'react-bootstrap';
import SliderOne from '../assets/images/slider-1_bg.jpg';
import SliderTwo from '../assets/images/slider-2_bg.jpg';
import SliderThree from '../assets/images/slider-3_bg.jpg';
import YellowAni from '../assets/images/yellow-ani.png';
import BorderAni from '../assets/images/border_ani.png';
import LogoBack from '../assets/images/logo_back_ani.png';
import headerData from '../header.json';
import axios from 'axios';

class Slider extends Component{
	/*constructor(props) {
    super(props);

    this.state = {
      	array: []
    	};
  	}*/

	/*componentDidMount(){
		console.log(headerData);
	    fetch('./header.json')
	      .then(({ data })=> {
	        console.log(data);
	      })
	    .catch((err)=> {})
	}*/

	render(){
		return(
			<Fragment>
				<div className="header_slider_area">
			    <Carousel>
				  <Carousel.Item interval={6000}>
				    <img
				      className="d-block w-100"
				      src={SliderOne}
				      alt="First slide"
				    />
				    <Carousel.Caption>
				      <h3>Our Prides</h3>
				      <h1>School of Future</h1>
				      <a href="https://sof.edu.bd">
				      	<Button className="learn_more">learn more</Button>
				      </a>
				      <a href="#">
				      	<Button className="touch">Get in touch</Button>
				      </a>
				    </Carousel.Caption>
				  </Carousel.Item>
				</Carousel>
				<div className="border_animation_left">
				    <img src={BorderAni} alt="border_ani_img" />
				</div>
				<div className="border_animation_right">
				    <img src={BorderAni} alt="border_ani_img" />
				</div>
				<div className="yellow_animation">
				    <img src={YellowAni} alt="yellow_ani_img" />
				</div>
				<div className="logo_back_animation">
				    <img src={LogoBack} alt="logo_ani_img" />
				</div>
				</div>
			</Fragment>
		)
	}
}

export default Slider;