import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function SolutionsForInstitutions() {
  
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Cloud Computing</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Cloud Computing</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>We provide the on-demand computer system resources specially the data
                storage and computing power including server management, storage, databases, networking,
                software and analytics, involving the hardware and software components required for proper
                implementation of a cloud computing model. In terms of our cloud service, we prefer to go with a
                Software-As-A-Service solution to our clients, where they can use our system with their device
                through the internet.
                </p>
                <p>Our Expertise and Works in Cloud Computing</p> 

                <p>In terms of expertise in the cloud computing sector, Team Creative has vast levels of experience and expertise in terms of managing cloud service based products and projects. Details of some of these products and projects are given below:</p>  

                <p>School of Future: One of our biggest pride, the 'Sheikh Russel School of Future’ is an extension of ‘Sheikh Russel Digital Lab Project (2nd Phase) - 2021’ is a scheme that sheds light in this education field inspired by Smart Schools in Finland and ensures the coverage of 300 reputed schools countrywide among 300 parliamentary seats, providing compatible IT system, digital content creation, learning management system, comprehensive E-learning for students, extensive virtual tutoring guidance for teachers, public attention and all necessary measures required to create an aim proved teaching and seamless learning environment.</p> 

                <p>This comprehensive Learning Management System is designed to make the life of students, teachers, administrative staff and parents’ lives simpler. As this Learning Management System stays within an active Cloud server, it allows all teachers, students and parents to be able to study and learn whenever they want, and from anywhere. The cloud service manages every data and information in the system, along with serving the users the academic, non-academic and monitoring facility online.</p> 

                <p>Currently School of Future involves 450,000 Students, 500,000 Parents & 30,000 Teachers countrywide in an active network. The whole system involves active Cloud Computing management. </p> 

                <p>CLASSTUNE LMS: Aimed to build a common interactive platform for all stakeholders including all students, parents, teachers, and the administrative teams of all schools to collaborate and embrace technology, CLASSTUNE is a Comprehensive Learning Management System which evolves the old school method into digitization. It is a cloud based Software as a Service (SaaS) where all involved parties have access to the system, via Internet from desktop or smartphone.</p> 

                <p>As an online school using cloud servers, CLASSTUNE helps to amalgamate all important data of students and teachers in a deliberate manner to help the school administrative head or the appointment in charge to monitor all curricular activities with a business intelligence dashboard where parameters for different measures can be set, reports collected, enabling easier evaluation and effective decision making. Functionable with 32 modules, CLASSTUNE serves with their effective online service with cloud computing technology.</p>  

                <p>CLASSPAY: With an aim to make the fees collection process easy, CLASSPAY is an online fees collection platform, fast and secure for all educational institutions across the nation.</p> 

                <p>CLASSPAY allows the users/parents to finish the whole fees payment process, and access related information digitally, instantly, and accurately in real-time. This reduces time, effort, and human resources, making the process fast, safe, and non-erroneous.</p>  

                <p>CLASS ADMISSION: The Class Admission platform is a comprehensive resource for college and school applicants. It enables users to create and manage an account via which you can collect admission forms and admit cards, you can make payments and also keep track of the progress of your application.</p> 

                <p>NDC 4.0: NDC 4.0 is the most efficient solution for National Defence College, easily accessible and effectively works on all platforms with real time updates. It accelerates engagement of all stakeholders and advocates corrective measures towards improving learning outcomes.</p> 

                <p>Decorated with 8 major modules, NDC 4.0 delivers a real time update and authorization even to a secured organization like Defence college through cloud service.</p> 

                <p>STC&S: A Learning Management System (LMS) for the purpose of conducting training for all stakeholders of Signal Training Center & School, Jashore Cantonment, Jashore- STC&S was developed. It is highly scalable, allowing scalability to grow with the academy’s needs. Authority of STC&S has all time accessibility on robust tracking, completion rates, and assessment results on subjects, enrollment vs dropouts and most importantly KPI.</p> 

                <p>UNDP Training Tracking Tool: A training management system for ‘Training, Exercise and Drills (TED) Program’ in association with ‘Urban Resilience Project (DDM Part)’. The training planning, design, conduction and facilitation is under the supervision of Disaster Response and Recovery Facility (DRRF) and UNDP Bangladesh. The Training Management System has the provision of managing online course materials, monitoring daily activities and generating a monthly report system with a holistic view of a dynamic dashboard.</p> 

                <p>Edoozz: As a cloud based Software-As-A-Service, Edoozz provides a fast and easy Learning Management System specially designed for university authority and independent trainers. It has various modules like live class hosting, storing documents, managing learning resources etc.</p> 

                <p>All of the above mentioned products and services are created in Cloud Computing method to deliver their Software-As-A-Service technology. Through our robust cloud service, we ensure to provide a seamless, hassle-free online service to the users and stakeholders of such systems.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}

export default SolutionsForInstitutions;
