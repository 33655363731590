import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>UNDP TTT</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>UNDP TTT</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>A training management system for ‘Training, Exercise and Drills (TED) Program’ in association with ‘Urban Resilience Project (DDM Part)’. The training planning, design, conduction and facilitation is under the supervision of Disaster Response and Recovery Facility (DRRF) and UNDP Bangladesh. The Training Management System has the provision of managing online course materials, monitoring daily activities and generating a monthly report system with a holistic view of a dynamic dashboard.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
