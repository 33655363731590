import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function Campaigns() {
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Science Rocks</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Science Rocks</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>To eradicate the fear of science and make it an interesting topic for children, our team came up with the show Doze presents Science Rocks. The show was aired on Channel i, which is one of the leading channels in all of Bangladesh. A web portal and mobile application were developed and then moderated by experienced science teachers. Students were able to ask questions, take quizzes and also get help from each other conveniently.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
export default Campaigns;
