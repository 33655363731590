import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import "../assets/css/solutionsPageCss.css";
import HeaderNav from '../components/HeaderNav';
import Footer from '../components/Footer';

function SolutionsForInstitutions() {
  
  return (
    <div>
      <div className='pageBg'>
        <HeaderNav/>
        <div className='PageTitle'>
            <h3>Website Development</h3>
        </div>
      </div>
      <div className='pageArea'>
        <Container>
          <div className='solutionTitle'>
            <h1>Website Development</h1>
          </div>
          <Row>
            <Col>
              <div className='solution_discription'>
                <p>Do you have a well-established business but face trouble reaching out to your market? Or need an active platform to showcase your products and services? Create a user-friendly and interactive website to engage with your audience and connect with business easily. Our provision in designing websites has broadened the ways to develop, customize and mobilize aesthetically pleasing imagery for your web page.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}

export default SolutionsForInstitutions;
