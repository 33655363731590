import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import Campaigns from './components/Campaigns';
import CompanyOverview from './pages/company-overview';
import MissionVision from './pages/mission-vision';
import ClassTune from './pages/classtune';
import Classpay from './pages/classpay';
import Edoozz from './pages/edoozz';
import ClassAdmission from './pages/class-admission';
import ClassPayroll from './pages/class-payroll';
import SOF from './pages/sof';
import SpellingBee from './pages/spelling-bee';
import BeSmartUseHeart from './pages/be-smart-use-heart';
import ScienceRocks from './pages/science-rocks';
import BangladeshFirst from './pages/bangladesh-first';
import NDC from './pages/ndc';
import STCS from './pages/stcs';
import UNDPTTT from './pages/undp-ttt';
import WorldTeachersDay from './pages/world-teachers-day';
import Portfolio from './pages/portfolio';
import BusinessConsultancy from './pages/business-consultancy';
import DigitalSolution from './pages/digital-solution';
import Website from './pages/website';
import AppDevelopment from './pages/app-development';
import CloudComputing from './pages/cloud-computing';

function AllRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/campaings" element={<Campaigns />} />
        <Route path="/company-overview" element={<CompanyOverview />} />
        <Route path="/mission-vision" element={<MissionVision />} />
        <Route path="/classtune" element={<ClassTune />} />
        <Route path="/classpay" element={<Classpay />} />
         <Route path="/edoozz" element={<Edoozz />} />
        <Route path="/class-admission" element={<ClassAdmission />} />
        <Route path="/class-payroll" element={<ClassPayroll />} />
        <Route path="/sof" element={<SOF />} />
        <Route path="/spelling-bee" element={<SpellingBee />} />
        <Route path="/be-smart-use-heart" element={<BeSmartUseHeart />} />
        <Route path="/science-rocks" element={<ScienceRocks />} />
        <Route path="/bangladesh-first" element={<BangladeshFirst />} />
        <Route path="/ndc" element={<NDC />} />
        <Route path="/stcs" element={<STCS />} />
        <Route path="/undp-ttt" element={<UNDPTTT />} />
        <Route path="/world-teachers-day" element={<WorldTeachersDay />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/business-consultancy" element={<BusinessConsultancy />} />
        <Route path="/digital-solution" element={<DigitalSolution />} />
        <Route path="/website" element={<Website />} />
        <Route path="/app-development" element={<AppDevelopment />} />
        <Route path="/cloud-computing" element={<CloudComputing />} />
      </Routes>
    </>
  );
}

export default AllRoutes;
